/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre",
    a: "a"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      children: "Styled bindings"
    }), "\n", _jsx(_components.h3, {
      children: "Automatic"
    }), "\n", _jsxs(_components.p, {
      children: ["Using xstyled's ", _jsx(_components.code, {
        children: "styled"
      }), ", all spacing scales defined are automatically bound to all margins, paddings and gap attributes:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import styled from '@xstyled/...'\n\nconst Card = styled.button`\n  margin: 3;\n`\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["To learn more about styled syntax, read ", _jsx(_components.a, {
        href: "@TODO-link",
        children: "styled syntax documentation"
      }), "."]
    }), "\n", _jsx(_components.h3, {
      children: "Manual"
    }), "\n", _jsxs(_components.p, {
      children: ["It is possible to manually bind a spacing scale using ", _jsx(_components.code, {
        children: "th.space"
      }), " utility:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import styled from '...'\nimport { th } from '@xstyled/...'\n\nconst Card = styled.button`\n  margin: calc(2px + ${th.space(3)});\n`\n"
      })
    }), "\n", _jsx(_components.h2, {
      children: "Hooks"
    }), "\n", _jsxs(_components.p, {
      children: ["Get a spacing scale in any component using ", _jsx(_components.code, {
        children: "useSpace"
      }), " hook:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import { useSpace } from '@xstyled/...'\n\nfunction Card() {\n  const space = useSpace(3)\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
